import {toast} from 'react-toastify';
import {ToastId} from "@app/constants/ToastId";
import {TFunction} from "react-i18next";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

export const statusErrorMessageHandler = (response: FetchBaseQueryError, t: TFunction) => {
    switch (response.status) {
        case 401: {
            toast(`${t('toast.statusError.401')}`, {type: 'error', toastId: ToastId.ERROR_TOAST});
            break;
        }
        case 405: {
            toast(`${t('toast.statusError.405')}`, {type: 'error', toastId: ToastId.ERROR_TOAST});
            break;
        }
        case 413: {
            toast(`${t('toast.statusError.413')}`, {type: 'error', toastId: ToastId.ERROR_TOAST});
            break;
        }
        case 415: {
            toast(`${t('toast.statusError.415')}`, {type: 'error', toastId: ToastId.ERROR_TOAST});
            break;
        }
        case 422: {
            toast(`${t('toast.statusError.422')}`, {type: 'error', toastId: ToastId.ERROR_TOAST});
            break;
        }
        case 417: {
            // @ts-ignore
            toast(`${response?.data?.body} ${t('toast.seemsToBeCorruptedPdf')}`, {
                type: 'error',
                toastId: ToastId.ERROR_TOAST
            });
            break;
        }
        default:
            break;
    }
};
