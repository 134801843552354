import React from 'react';

function PricingIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_2189_924)">
                <path
                    d="M16 7.71429C15.3381 6.88743 14.4994 6.32812 13.5891 6.1065C12.6788 5.88489 11.7375 6.01082 10.8831 6.46851C10.0287 6.92621 9.29939 7.69529 8.78649 8.67932C8.2736 9.66335 8 10.8185 8 12C8 13.1815 8.2736 14.3367 8.78649 15.3207C9.29939 16.3047 10.0287 17.0738 10.8831 17.5315C11.7375 17.9892 12.6788 18.1151 13.5891 17.8935C14.4994 17.6719 15.3381 17.1126 16 16.2857"
                    stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13 10H6M6 14H13" stroke="black" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="2"/>
            </g>
            <defs>
                <clipPath id="clip0_2189_924">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default PricingIcon;
